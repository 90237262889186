import axios from '@/requests';

function houseSearch(searchKey) {
  let form = new FormData();
  form.append('searchKey', searchKey);
  return axios.post('/guanjiangyuan/house/search', form);
}

function houseAdd(house) {
  return axios.post('/guanjiangyuan/house/add', house);
}

function houseUpdate(house) {
  return axios.post('/guanjiangyuan/house/update', house);
}

function houseDel(id) {
  return axios.post('/guanjiangyuan/house/del/' + id);
}

function peopleList(houseNo) {
  return axios.post(`/guanjiangyuan/people/list/${houseNo}`);
}

function peopleAdd(people) {
  return axios.post('/guanjiangyuan/people/add', people);
}

function peopleUpdate(people) {
  return axios.post('/guanjiangyuan/people/update', people);
}

function peopleDel(id) {
  return axios.post(`/guanjiangyuan/people/del/${id}`);
}

function download() {
  return axios.get('/guanjiangyuan/download');
}

export {
  houseSearch, houseAdd, houseUpdate, houseDel, peopleList, peopleAdd, peopleDel, peopleUpdate, download
};
