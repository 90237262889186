<template>
  <div class="main-box">

    <van-sticky>
      <van-nav-bar title="房间管理"
                   right-text="添加"

                   left-text="下载表格"
                   @click-right="onClickRight"
                   @click-left="onClickLeft">

      </van-nav-bar>

    </van-sticky>
    <van-search
        v-model="searchKey"
        placeholder="请输入搜索关键词"
        input-align="center"
        @search="onSearch"
        @cancel="onCancel"
    />


    <van-list finished-text="没有更多了">
      <van-swipe-cell v-for="(item,index) in tableData" :key="index">
        <van-cell :title="item.houseNo"
                  :value="item.community"
                  center
                  :label="getLabel(item)"
                  :to="{'name':'peopleList','params':{'houseItem':JSON.stringify(item),'searchKey':searchKey}}">
        </van-cell>
        <template #right>
          <van-button square
                      text="修改"
                      type="primary"
                      class="delete-button"
                      @click="onClickEditButton(item)"/>
          <van-button square
                      text="删除"
                      type="danger"
                      class="delete-button"
                      @click="onClickDelButton(item)"/>
        </template>
        <!--        <template #left>
                  <van-button square
                              text="物理删除" type="danger" class="delete-button" @click="onClickDelete(item)"/>
                </template>-->
      </van-swipe-cell>
    </van-list>

  </div>

</template>

<script>

import {Dialog, Toast} from 'vant';
import { houseDel, houseSearch} from '@/requests/guanjiangyuan';



export default {
  'name': 'HouseListView',
  data() {
    return {
      'searchKey': '',
      'loading': false,
      'refreshing': false,
      'finished': false,
      'rules': {
        'name': [{'required': true, 'message': '请输入提醒名字', 'trigger': 'blur'}],
        'email': [{'required': true, 'type': 'email', 'message': '请输入提醒邮箱', 'trigger': 'blur'}],
        'remindTime': [{'required': true, 'message': '请输入提醒时间', 'trigger': 'blur'}],
        'remindType': [{'required': true, 'message': '请输入提醒类型', 'trigger': 'blur'}],
        'intervalTime': [{'required': true, 'message': '请输入间隔时间', 'trigger': 'blur'}],
        'state': [{'required': true, 'message': '请输入提醒状态', 'trigger': 'blur'}]
      },

      'currentIndex': 0,
      'dialogTitle': '',
      'formItem': {},
      'editVisible': false,
      'isCreate': true,
      'startX': null,
      'startY': null,
      'currentPage': 1,
      'pageSize': 20,
      'totalPage': 10,
      'remindType': [],
      'remindState': [],

      'tableData': [/*
        {
          'name': 'ceshi1',
          'remindTime': '2022-02-02 14:00:31',
          'state': '正常'
        },
        {
          'name': 'ceshi2',
          'remindTime': '2022-02-02 14:00:31',
          'state': '正常'
        },
        {
          'name': 'ceshi3',
          'remindTime': '2022-02-02 14:00:31',
          'state': '正常'
        }*/
      ]

    };
  },
  mounted() {
    this.currentPage = 0;
    this.searchKey = this.$route.params.searchKey;
    if (this.searchKey) {
      this.onSearch();
    }
  },
  'methods': {
    getLabel(item) {
      let res = '',
        give = item.isGive;
      if (give != '' && give) {
        res = res + give + '交房，';
      }
      if (item.status && item.status != '') {
        res = res + item.status + ',';
      }
      if (item.register && item.register != '') {
        res = res + item.register + ',';
      }
      if (item.remark && item.remark != '') {
        res = res + item.remark;
      }
      return res;
    },
    onSearch() {
      console.log('search');
      this.getTableData(0);
    },
    onCancel() {
      console.log('cancel');
    },
    onRefresh() {
      this.currentPage = 1;
      this.finished = false;
      this.loading = true;
      this.getTableData(0);
    },
    onChange() {
      this.getTableData(2);
    },
    onLoad() {
      this.currentPage = this.currentPage + 1;
      this.getTableData(1);
    },
    onClickEditButton(item) {
      this.$router.push({'name': 'houseEdit', 'params': {'houseItem': JSON.stringify(item)}});
    },
    onClickDelButton(item) {
      this.onClickDelete(item.id).then(res => {
        if (res.code === 200) {
          this.getTableData();
          Toast.success('成功');
        } else {
          Toast.fail(res.message);
        }
      });

    },
    onClickDelete(id) {
      return new Promise(() => {
        Dialog.confirm({'title': '确定删除吗？'})
          .then(confirm => {
            if (confirm === 'confirm') {
              //删除
              houseDel(id).then(res => {
                if (res.code === 200) {
                  this.getTableData();
                  Toast.success('成功');
                } else {
                  Toast.fail(res.message);
                }
              });
            }
          });
      });
    },
    getTableData() {
      houseSearch(this.searchKey).then(res => {
        if (res.code === 200) {
          this.tableData = res.data;
        } else {
          Toast.fail(res.message);
        }
      }).catch(e => {
        Toast.fail(e.message);
      });

    },
    onClickLeft() {
      console.log('下载');
      window.location.href='https://guanjiangyuan.zhangjianyong.top/api/guanjiangyuan/download';
      /* download(res=>{
        if (res!=200){
          Toast.fail(res.message);
        }
      });*/
    },
    onClickRight() {
      this.$router.push('/houseAdd');
    },

    getItemLabel(item) {
      return item.remindType + ':' + item.remindTime;
    },
    getItemState(item) {
      return item.delFlag == 1 ? '已删除' : item.state;
    },
    getItemTitle(item) {

      return item.name;
    }
  },
  'computed': {
    'user': {
      get() {
        return this.$store.state.user;
      },
      set(val) {
        this.$store.commit('setUser', val);
      }
    }
  }
}
;
</script>

<style scoped lang="scss">

.main-box {
  background-color: #f1f0f0;
  padding: 0 0 0 0;

  .van-pull-refresh {

  }
}

.van-list {
  padding-top: 10px;

  .van-cell {
    text-align: left;
  }

  .delete-button {
    height: 100%;
  }
}

</style>
