<template>
  <div class="main-box">
    <van-sticky>
      <van-nav-bar title="修改住户"
                   left-arrow
                   left-text="返回"
                   @click-left="onClickLeft">

      </van-nav-bar>
    </van-sticky>

    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
            v-model="form.houseNo"
            name="房号"
            label="房号"
            readonly
            placeholder="请输入房号"
            :rules="[{ required: true, message: '请填写房号' }]"
        />
        <van-field
            v-model="form.name"
            name="姓名"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
            v-model="form.idNo"
            name="身份证"
            label="身份证"
            placeholder="请输入"
        />
        <van-field
            v-model="form.phone"
            name="手机号码"
            label="手机号码"
            placeholder="请输入手机号码"
        />
        <van-field
            v-model="form.isMaster"
            name="是否是户主"
            label="是否是户主"
            placeholder="请输入是否是户主"
        />
        <van-field
            v-model="form.isLive"
            name="是否居住"
            label="是否居住"
            placeholder="请输入是否居住"
        />
        <van-field
            v-model="form.householdRegister"
            name="户籍"
            label="户籍"
            placeholder="请输入户籍"
        />
        <van-field
            v-model="form.remark"
            name="备注"
            label="备注"
            placeholder="请输入备注"
        />

      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import {Toast} from 'vant';
import {peopleUpdate} from '@/requests/guanjiangyuan';

export default {
  'name': 'PeopleEditView',
  data() {
    return {
      'form': {
        'id': '',
        'houseNo': '',
        'name': '',
        'idNo': '',
        'phone': '',
        'isMaster': '',
        'isLive': '',
        'householdRegister': '',
        'remark': ''
      },
      'searchKey': '',
      'houseItem': '',
      'house': {
        'id': '',
        'community': '',
        'buildingNo': '',
        'houseNo': '',
        'remark': '',
        'isGive': '',
        'status': '',
        'register': ''
      }
    };
  }, mounted() {
    this.houseItem = this.$route.params.house;
    this.searchKey = this.$route.params.searchKey;
    let people = this.$route.params.people;
    if (!people) {
      this.onClickLeft();
      return;
    }

    people = JSON.parse(people);

    this.form.id = people.id;
    this.form.houseNo = people.houseNo;
    this.form.name = people.name;
    this.form.idNo = people.idNo;
    this.form.phone = people.phone;
    this.form.isMaster = people.isMaster;
    this.form.isLive = people.isLive;
    this.form.householdRegister = people.householdRegister;
    this.form.remark = people.remark;


  }, 'methods': {
    onClickLeft() {
      this.$router.push({'name': 'peopleList', 'params': {'houseItem': this.houseItem, 'searchKey': this.searchKey}});
    },
    onSubmit() {
      peopleUpdate(this.form).then(res => {
        if (res.code == 200) {
          this.onClickLeft();
        } else {
          Toast.fail(res.message);
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
