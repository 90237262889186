<template>
  <van-sticky>
    <van-nav-bar :title="house.houseNo"
                 right-text="添加"
                 left-arrow
                 left-text="返回"
                 @click-right="onClickRight"
                 @click-left="onClickLeft">

    </van-nav-bar>

  </van-sticky>

  <van-list finished-text="没有更多了">
    <van-swipe-cell v-for="(item,index) in tableData" :key="index">
      <van-cell :title="item.name"
                :value="getValue(item)"
                center
                :label="getLabel(item)"
                :to="{'name': 'peopleEdit', 'params': {'house': JSON.stringify(this.house),'searchKey': this.searchKey,'people': JSON.stringify(item)}}">
      </van-cell>
      <template #right>
        <van-button square
                    text="修改"
                    type="primary"
                    class="delete-button"
                    @click="onClickEditButton(item)"/>
        <van-button square
                    text="删除"
                    type="danger"
                    class="delete-button"
                    @click="onClickDelButton(item)"/>
      </template>
    </van-swipe-cell>
  </van-list>

</template>

<script>
import {peopleDel, peopleList} from '@/requests/guanjiangyuan';
import {Dialog, Toast} from 'vant';


export default {
  'name': 'PeopleListView',
  data() {
    return {
      'searchKey': '',
      'tableData': [],
      'house': {
        'id': '',
        'community': '',
        'buildingNo': '',
        'houseNo': '',
        'remark': '',
        'isGive': '',
        'status': '',
        'register': ''
      }
    };
  },
  mounted() {
    let houseItem = this.$route.params.houseItem;
    this.searchKey = this.$route.params.searchKey;
    if (!houseItem) {
      this.onClickLeft();
      return;
    }
    houseItem = JSON.parse(houseItem);

    this.house.id = houseItem.id;
    this.house.community = houseItem.community;
    this.house.buildingNo = houseItem.buildingNo;
    this.house.houseNo = houseItem.houseNo;
    this.house.remark = houseItem.remark;
    this.house.isGive = houseItem.isGive;
    this.house.status = houseItem.status;
    this.house.register = houseItem.register;
    this.getTableData();
  }, 'methods': {
    getValue(item) {
      let res='';
      if (item.isMaster&&item.isMaster!=''){
        res=res+item.isMaster+'户主，';
      }
      if (item.isLive&&item.isLive!=''){
        res=res+item.isLive+'居住，';
      }
      if (item.householdRegister&& item.householdRegister){
        res=res+item.householdRegister+',';
      }
      if (item.remark&& item.remark){
        res=res+item.remark+',';
      }
      return res;
    },
    getLabel(item) {
      return item.idNo + ',' + item.phone;
    },
    onClickEditButton(item) {
      this.$router.push({
        'name': 'peopleEdit', 'params': {
          'house': JSON.stringify(this.house),
          'searchKey': this.searchKey,
          'people': JSON.stringify(item)
        }
      });
    },
    onClickDelButton(item) {
      this.onClickDelete(item.id).then(res => {
        if (res.code === 200) {
          this.getTableData();
          Toast.success('成功');
        } else {
          Toast.fail(res.message);
        }
      });
    },
    onClickDelete(id) {
      return new Promise(() => {
        Dialog.confirm({'title': '确定删除吗？'})
          .then(confirm => {
            if (confirm === 'confirm') {
              //删除
              peopleDel(id).then(res => {
                if (res.code === 200) {
                  this.getTableData();
                  Toast.success('成功');
                } else {
                  Toast.fail(res.message);
                }
              });
            }
          });
      });
    },
    onClickLeft() {
      this.$router.push({'name': 'main', 'params': {'searchKey': this.searchKey}});
    },
    onClickRight() {
      this.$router.push({
        'name': 'peopleAdd',
        'params': {'house': JSON.stringify(this.house), 'searchKey': this.searchKey}
      });
    },
    getTableData() {
      peopleList(this.house.houseNo).then(res => {
        if (res.code == 200) {
          this.tableData = res.data;
        } else {
          Toast.fail(res.message);
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
