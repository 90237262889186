import {createApp} from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import '@vant/touch-emulator';


const app = createApp(App);
app.use(router);
app.use(store);


import {Button} from 'vant';
app.use(Button);
import {Form, Field} from 'vant';

app.use(Form);
app.use(Field);
import {Col, Row} from 'vant';

app.use(Col);
app.use(Row);
import {List} from 'vant';

app.use(List);
import {Cell, CellGroup} from 'vant';

app.use(Cell);
app.use(CellGroup);
import {Toast} from 'vant';

app.use(Toast);
import {Tabbar, TabbarItem} from 'vant';

app.use(Tabbar);
app.use(TabbarItem);
import {NavBar} from 'vant';

app.use(NavBar);
import {DatetimePicker} from 'vant';

app.use(DatetimePicker);
import {Picker} from 'vant';

app.use(Picker);
import {Popup} from 'vant';

app.use(Popup);
import {Grid, GridItem} from 'vant';

app.use(Grid);
app.use(GridItem);
import {Icon} from 'vant';

app.use(Icon);
import {SwipeCell} from 'vant';

app.use(SwipeCell);
import {PullRefresh} from 'vant';

app.use(PullRefresh);
import {Sticky} from 'vant';

app.use(Sticky);
import {Divider} from 'vant';

app.use(Divider);
import {Uploader} from 'vant';

app.use(Uploader);
import {Tab, Tabs} from 'vant';

app.use(Tab);
app.use(Tabs);
import {ConfigProvider} from 'vant';

app.use(ConfigProvider);
import {DropdownMenu, DropdownItem} from 'vant';

app.use(DropdownMenu);
app.use(DropdownItem);
import {Swipe, SwipeItem} from 'vant';

app.use(Swipe);
app.use(SwipeItem);
import {Card} from 'vant';

app.use(Card);

import {Stepper} from 'vant';

app.use(Stepper);

import { Search } from 'vant';
app.use(Search);

app.mount('#app');



