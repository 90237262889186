<template>
  <div class="main-box">
    <van-sticky>
      <van-nav-bar title="新增住户"
                   left-arrow
                   left-text="返回"
                   @click-left="onClickLeft">

      </van-nav-bar>
    </van-sticky>

    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
            v-model="form.houseNo"
            name="房号"
            label="房号"
            readonly
            placeholder="请输入房号"
            :rules="[{ required: true, message: '请填写房号' }]"
        />
        <van-field
            v-model="form.name"
            name="姓名"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
            v-model="form.idNo"
            name="身份证"
            label="身份证"
            placeholder="请输入"
        />
        <van-field
            v-model="form.phone"
            name="手机号码"
            label="手机号码"
            placeholder="请输入手机号码"
        />
        <van-field
            v-model="form.isMaster"
            name="是否是户主"
            label="是否是户主"
            placeholder="请输入是否是户主"
        />
        <van-field
            v-model="form.isLive"
            name="是否居住"
            label="是否居住"
            placeholder="请输入是否居住"
        />
        <van-field
            v-model="form.householdRegister"
            name="户籍"
            label="户籍"
            placeholder="请输入户籍"
        />
        <van-field
            v-model="form.remark"
            name="备注"
            label="备注"
            placeholder="请输入备注"
        />

      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import {peopleAdd} from '@/requests/guanjiangyuan';
import {Toast} from 'vant';

export default {
  'name': 'PeopleAddView',
  data(){
    return{
      'form':{
        'houseNo':'',
        'name':'',
        'idNo':'',
        'phone':'',
        'isMaster':'',
        'isLive':'',
        'householdRegister':'',
        'remark':''
      },
      'searchKey':'',
      'house': {
        'id': '',
        'community': '',
        'buildingNo': '',
        'houseNo': '',
        'remark': '',
        'isGive': '',
        'status': '',
        'register': ''
      }
    };
  },mounted() {
    let houseItem = this.$route.params.house;
    this.searchKey=this.$route.params.searchKey;
    if (!houseItem) {
      this.onClickLeft();
      return;
    }
    houseItem = JSON.parse(houseItem);

    this.house.id = houseItem.id;
    this.house.community = houseItem.community;
    this.house.buildingNo = houseItem.buildingNo;
    this.house.houseNo = houseItem.houseNo;
    this.house.remark = houseItem.remark;
    this.house.isGive = houseItem.isGive;
    this.house.status = houseItem.status;
    this.house.register = houseItem.register;
    this.form.houseNo=this.house.houseNo;
  },'methods':{
    onClickLeft(){
      this.$router.push({'name':'peopleList','params':{'houseItem':JSON.stringify(this.house),'searchKey':this.searchKey}});
    },
    onSubmit(){
      peopleAdd(this.form).then(res=>{
        if (res.code==200){
          this.onClickLeft();
        }else {
          Toast.fail(res.message);
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
