<template>
  <div class="main-box">
    <van-sticky>
      <van-nav-bar title="修改房子"
                   left-arrow
                   left-text="返回"
                   @click-left="onClickLeft">

      </van-nav-bar>
    </van-sticky>

    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
            v-model="form.community"
            name="小区"
            label="小区"
            readonly
            placeholder="请输入小区"
            :rules="[{ required: true, message: '请填写小区' }]"
        />
        <van-field
            v-model="form.buildingNo"
            name="楼号"
            label="楼号"
            readonly
            placeholder="请输入楼号"
            :rules="[{ required: true, message: '请填写楼号' }]"
        />
        <van-field
            v-model="form.houseNo"
            name="房号"
            label="房号"
            readonly
            @change="onChangeHouseNo"
            placeholder="请输入房号"
            :rules="[{ required: true, message: '请填写房号' }]"
        />
        <van-field
            v-model="form.isGive"
            name="有无交房"
            label="有无交房"
            placeholder="请输入有无交房"
        />
        <van-field
            v-model="form.status"
            name="状态"
            label="状态(自购、空置、出租)"
            placeholder="请输入状态"
        />
        <van-field
            v-model="form.register"
            name="房管通登记"
            label="房管通登记"
            placeholder="请输入房管通登记"
        />
        <van-field
            v-model="form.remark"
            name="备注"
            label="备注"
            placeholder="请输入备注"
        />


      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { houseUpdate} from '@/requests/guanjiangyuan';
import {Toast} from 'vant';

export default {
  'name': 'HouseEditView',
  data() {
    return {
      'form': {
        'id':'',
        'community': '观江苑',
        'buildingNo':'',
        'houseNo': '',
        'remark': '',
        'isGive': '',
        'status': '',
        'register': ''
      }
    };
  },
  mounted() {
    let houseItem = this.$route.params.houseItem;
    if (!houseItem) {
      this.$router.push('/main');
      return;
    }
    houseItem = JSON.parse(houseItem);

    this.form.id = houseItem.id;
    this.form.community = houseItem.community;
    this.form.buildingNo = houseItem.buildingNo;
    this.form.houseNo = houseItem.houseNo;
    this.form.remark = houseItem.remark;
    this.form.isGive = houseItem.isGive;
    this.form.status=houseItem.status;
    this.form.register=houseItem.register;
  },
  'methods': {
    onChangeHouseNo() {
      this.form.buildingNo = this.form.houseNo.split('-')[0];
    },
    onSubmit() {
      console.log(this.form);
      houseUpdate(this.form).then(res => {
        if (res.code === 200) {
          this.$router.push('/main');
        }else {
          Toast.success(res.message);
        }
      });
    },
    onClickLeft() {
      this.$router.push('/main');
    }
  },  'watch': {
    'form.houseNo':  function () {
      //console.log(o,n);
      this.onChangeHouseNo();
    }
  }
};
</script>

<style scoped>

</style>
